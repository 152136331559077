<template>
  <div class="wrapper-maintain">
    <div class="maintain-left">
      <vrpPath></vrpPath>
    </div>
    <div class="maintain-right">
      <div class="maintain-top">
        <div class="site-form">
          <el-descriptions
            :column="1"
            :colon="false"
            :labelStyle="{
              'line-height': '45px',
              color: '#1ED0F8',
              width: '80px',
              display: 'flex',
              'justify-content': 'flex-end'
            }"
          >
            <el-descriptions-item label="维修站点">
              <el-select
                @change="handleChangeDepot"
                v-model="seletedDepot"
                placeholder="请选择维修站点"
                :popper-append-to-body="false"
              >
                <el-option
                  v-for="item in depotOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-descriptions-item>
          </el-descriptions>
        </div>
        <div class="site-add" @click="dialogDepotVisible = true">
          <img src="@/assets/img/add.png" alt="" width="24px" height="24px" />
          <p class="btn-text">新增设备</p>
        </div>
      </div>
      <div class="maintain-footer" style="height: 90%">
        <border-box :src="srcTop" style="height: 100%">
          <span slot="title" class="title border-title">维保账号</span>
          <div slot="box-container" style="height: 100%">
            <el-scrollbar style="height: 90%">
              <el-card
                class="box-card"
                v-for="(item, index) in maintainTasks"
                :key="index"
              >
                <div slot="header" class="clearfix">
                  <span style="font-size: 16px"
                    >维保账号：{{ item.worker }}</span
                  >
                  <el-button
                    style="float: right; padding: 3px 0; color: #63e2ff"
                    type="text"
                    >删除</el-button
                  >
                </div>
                <el-collapse
                  accordion
                  v-for="(item1, index) in item.tasks"
                  :key="index"
                >
                  <el-collapse-item>
                    <template slot="title">
                      <i
                        :class="
                          item1.isFinished == '已完成'
                            ? 'el-icon-success'
                            : 'el-icon-info'
                        "
                      ></i
                      >{{ item1.address }}
                    </template>
                    <el-row :gutter="20">
                      <el-col :span="6"><div>任务编号：</div></el-col>
                      <el-col :span="6"
                        ><div>{{ item1.taskId }}</div></el-col
                      >
                      <el-col :span="6"><div>任务情况：</div></el-col>
                      <el-col :span="6"
                        ><div>{{ item1.situation }}</div></el-col
                      >
                    </el-row>
                    <el-row :gutter="20">
                      <el-col :span="6"><div>预计维修时长：</div></el-col>
                      <el-col :span="6"
                        ><div>{{ item1.duration }}</div></el-col
                      >
                      <el-col :span="6"><div>任务完成情况：</div></el-col>
                      <el-col :span="6"
                        ><div>
                          {{ item1.isFinished }}
                        </div></el-col
                      >
                    </el-row>
                  </el-collapse-item>
                </el-collapse>
              </el-card>

              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span>新建维保账号</span>
                </div>
                <div
                  style="
                    width: 100%;
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <i
                    class="el-icon-plus"
                    @click="dialogWorkerVisible = true"
                  ></i>
                </div>
              </el-card>
            </el-scrollbar>
          </div>
        </border-box>
      </div>

      <!-- 新增维修站点弹出框 -->
      <el-dialog
        title="添加维修站点"
        width="40%"
        height="60%"
        :visible.sync="dialogDepotVisible"
        center
        class="newDialog"
        :append-to-body="true"
      >
        <el-row :gutter="5">
          <el-col :span="15"
            ><el-input
              v-model="form.mAddress"
              class="textAddress"
              placeholder="请输入维修站点地址"
            ></el-input>
          </el-col>
          <el-col :span="3">
            <el-button
              class="butt3"
              type="info"
              value="查询"
              icon="el-icon-search"
              v-on:click="searchByStationName()"
            ></el-button
          ></el-col>
          <el-col :span="3"
            ><el-input
              v-model="form.mLocation.lng"
              class="textLocation"
              placeholder="经度"
            ></el-input
          ></el-col>

          <el-col :span="3"
            ><el-input
              v-model="form.mLocation.lat"
              class="textLocation"
              placeholder="纬度"
            ></el-input
          ></el-col>
        </el-row>

        <el-card class="new-map-card" shadow="never">
          <baidu-map
            class="depot-map"
            :center="{ lng: 116.404, lat: 39.915 }"
            :zoom="zoom"
            :scroll-wheel-zoom="true"
          >
            <bm-view style="width: 100%; height: 100%"></bm-view>
            <bm-local-search
              :keyword="form.mAddress"
              :auto-viewport="true"
              :location="location"
              :panel="false"
              @markersset="searchPoint"
            ></bm-local-search>
            <bm-geolocation
              anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
              :autoLocation="true"
              @locationSuccess="locatingPoint"
            ></bm-geolocation>
            <bm-marker
              :position="form.mLocation"
              :dragging="true"
              @dragend="dragedPoint"
              animation="BMAP_ANIMATION_BOUNCE"
            >
            </bm-marker>
          </baidu-map>
        </el-card>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogDepotVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitMaintainCenter"
            >确 定</el-button
          >
        </span>
      </el-dialog>

      <!-- 添加维保账号弹出框 -->
      <el-dialog
        title="添加维保账号"
        width="25%"
        height="60%"
        :visible.sync="dialogWorkerVisible"
        center
        class="newWorkerDialog"
        :append-to-body="true"
      >
        <el-form :model="formWorker">
          <el-row :gutter="5">
            <el-col :span="12">
              <el-form-item label="维修站点：" size="mini">
                <el-select
                  class="worker-center"
                  v-model="seletedDepot"
                  placeholder="请选择维修站点"
                >
                  <el-option
                    v-for="item in depotOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="联系方式：" size="mini">
                <el-input
                  v-model="formWorker.telephone"
                  class="worker-tel"
                  placeholder=""
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="5">
            <el-col :span="12">
              <el-form-item label=" 用 户 名 :" size="mini">
                <el-input
                  v-model="formWorker.workername"
                  class="worker-tel"
                  placeholder=""
                >
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="账号密码：" size="mini">
                <el-input
                  type="password"
                  show-password="true"
                  v-model="formWorker.password"
                  class="worker-tel"
                  placeholder=""
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogWorkerVisible = false">取 消</el-button>
          <el-button type="submit" @click="submitMaintainWorker"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  getMaintainPathData,
  postNewDepot,
  getMaintainDepotsData,
  postNewWorker,
  getMaintainWorkersData
} from '@/api/maintain.js'
import vrpPath from './vrp-path'
export default {
  components: { vrpPath },
  data() {
    return {
      srcTop: require('@/assets/img/line-chart.png'),
      seletedDepot: '',
      depotOptions: [],
      maintainTasks: [],
      dialogDepotVisible: false, //新建维保中心标志位
      dialogWorkerVisible: false,
      form: {
        userId: 0,
        mAddress: '',
        mLocation: { lng: 116.404, lat: 39.915 } //地图
      },
      formWorker: {
        depotId: '',
        workername: '',
        password: '',
        telephone: ''
      },
      location: '北京', //初始区域
      zoom: 15
    }
  },
  created() {
    // this.drawPath()
    this.getDepotsData()
  },
  methods: {
    //获取维保中心数据
    getDepotsData() {
      const uid = this.$store.state.uid
      getMaintainDepotsData({ uid }).then((res) => {
        this.depotOptions.length = 0
        for (var i = 0; i < res.length; i++) {
          this.depotOptions.push({
            value: res[i].depotId,
            label: res[i].address
          })
        }
        this.seletedDepot = res[0].depotId //默认选中第一个
        this.getWorkerData()
      })
    },

    //获取工人任务列表
    getWorkerData() {
      getMaintainWorkersData(this.seletedDepot).then((res) => {
        // console.log(res.data);
        this.maintainTasks = []
        for (var i = 0; i < res.allTasks.length; i++) {
          this.maintainTasks.push(res.allTasks[i])
          // console.log(this.maintainTasks);
        }
      })
    },
    //新建维保账号
    submitMaintainWorker() {
      this.formWorker.depotId = this.seletedDepot
      postNewWorker(this.seletedDepot, this.formWorker)
        .then((res) => {
          this.dialogWorkerVisible = false
          // console.log(res.data);
          this.$alert(res.data, '注册成功', {
            confirmButtonText: '确定',
            type: 'success',
            center: true
          })
        })
        .catch((err) => {
          // console.log(err)
          this.$alert('请补全信息', '注册失败', {
            confirmButtonText: '确定',
            type: 'warning',
            center: true
          })
        })
    },

    // map
    //切换维保单位
    selectDepot(e) {
      this.getWorkerData()
    },

    //搜索完成后
    searchPoint(e) {
      // console.log(e);
      this.form.mLocation.lng = e[0].point.lng
      this.form.mLocation.lat = e[0].point.lat
      // console.log("wangcheng");
    },

    //定位成功后
    locatingPoint(e) {
      // console.log(e);
      this.form.mLocation.lng = e.point.lng
      this.form.mLocation.lat = e.point.lat
    },

    //拖动位置点后
    dragedPoint(e) {
      // console.log(e);
      this.form.mLocation.lng = e.Bg.lng
      this.form.mLocation.lat = e.Bg.lat
    },
    //新建维保中心
    submitMaintainCenter() {
      const uid = this.$store.state.uid
      this.form.userId = parseInt(uid)
      console.log(this.form)
      postNewDepot(this.form)
        .then((res) => {
          this.dialogDepotVisible = false
          this.$alert('维保站点已注册！', '注册成功', {
            confirmButtonText: '确定',
            type: 'success',
            center: true
          })
          this.getDepotsData()
        })
        .catch((err) => {
          // console.log(err)
          this.$alert('请补全信息', '注册失败', {
            confirmButtonText: '确定',
            type: 'warning',
            center: true
          })
        })
    },
    handleChangeDepot(val) {
      this.seletedDepot = val
      this.getWorkerData()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper-maintain {
  width: 100%;
  height: 88%;
  display: flex;
  padding: 46px;
  .maintain-left {
    width: 65%;
    height: 100%;
    background: url('../../../assets/img/earth-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  .maintain-right {
    width: 35%;
  }
  .maintain-top {
    width: 100%;
    display: flex;

    .site-form {
      width: 100%;
      display: inline-block;
      ::v-deep .el-descriptions__body {
        background-color: transparent !important;
      }
    }
    .site-add {
      background-image: url(../../../assets/img/btn-bg.png);
      background-size: 100% 100%;
      width: 168px;
      height: 45px;
      cursor: pointer;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      float: right;
      margin-right: 10px;
      img {
        width: 28px;
        height: 28px;
        vertical-align: middle;
        margin-right: 10px;
      }
      p {
        color: #1ed0f8;
      }
    }
  }
  .maintain-footer {
    width: 100%;
    height: 75%;
  }
}

.depot-map {
  width: 100%;
  height: 400px;
}
.box-card {
  margin: 20px;
  width: 90%;
}

.new-map-card {
  width: 100%;
  height: 60%;
}

::v-deep.el-card {
  background: transparent;
  color: #63e2ff;
  border: 1px solid #152347;
}
::v-deep .el-collapse-item__header {
  background: transparent !important;
  color: #63e2ff;
  border: 1px solid #152347;
  font-size: 18px;
}
::v-deep .el-card__header {
  border-bottom: 1px solid #152347;
}

::v-deep .el-collapse {
  border-top: 1px solid #152347;
  border-bottom: 1px solid #152347;
}

::v-deep .el-collapse-item__wrap {
  background: transparent;
  color: #63e2ff;
  border: none;
}
::v-deep .el-collapse-item__content {
  color: #63e2ff;
  font-size: 16px;
}
</style>
