import request from '@/utils/request.js'

//获取车辆路径规划的结果
export function getMaintainPathData() {
  return request({
    url: '/api/v1/maintain/vrp',
    method: 'get'
  })
}
//获取所有维保场站数据

export function getMaintainDepotsData(params) {
  return request({
    url: '/api/v1/depots',
    method: 'get',
    params
  })
}
//注册新的维保场站
export function postNewDepot(data) {
  return request({
    url: '/api/v1/depots',
    method: 'post',
    data
  })
}
//获取其中一个场站中所有维保工人的数据

export function getMaintainWorkersData(pid) {
  return request({
    url: '/api/v1/depots/' + pid + '/workers',
    method: 'get'
  })
}
//创建新的维保工人

export function postNewWorker(pid, data) {
  return request({
    url: '/api/v1/depots/' + pid + '/workers',
    method: 'post',
    data
  })
}
