<template>
  <div id="vrp-path"></div>
</template>

<script>
import { getMaintainPathData } from '@/api/maintain.js'
import geoJson from '@/utils/vrpMap.json' //车辆路径地图数据
import { EleResize } from '@/utils/esresize.js'

export default {
  data() {
    return {
      //维修路径
      maintainBase: [],
      maintainTaskP1: [],
      maintainTaskP2: [],
      maintainTaskP3: [],
      maintainPaths: [],
      normalTasks: []
    }
  },
  created() {
    this.getPlanningPaths()
  },
  methods: {
    //获取路径规划结果
    getPlanningPaths() {
      getMaintainPathData().then((res) => {
        // console.log(res.data);

        for (var i = 0; i < res.length; i++) {
          var temp = JSON.parse(JSON.stringify(res[i]))
          // console.log(temp);
          if (i == 0) {
            this.normalTasks = temp.taskNorm
            // console.log(this.normalTasks);
          }
          this.maintainBase.push(temp.maintainBases)
          this.maintainTaskP1.push(temp.taskP1)
          this.maintainTaskP2.push(temp.taskP2)
          this.maintainTaskP3.push(temp.taskP3)
          this.maintainPaths.push(temp.paths)
          // this.normalTasks.push(temp.taskNorm);
          // console.log(this.normalTasks);
        }
        this.drawPath()
      })
    },

    //绘图基础
    drawLine(dom, option) {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById(dom))
      let resizeDiv = document.getElementById(dom)
      // 绘制图表
      myChart.setOption(option)
      // console.log(EleResize)
      //   console.log("执行到这一行了");
      let listener = function () {
        myChart.resize()
      }
      EleResize.on(resizeDiv, listener)
    },
    drawPath() {
      //计算坐标的缩放系数，x和y方向上的最小值
      var convertRatio = function (data) {
        var res = []
        var minX = data[0][0]
        var maxX = data[0][0]
        var minY = data[0][1]
        var maxY = data[0][1]

        for (var i = 1; i < data.length; i++) {
          minX = Math.min(minX, data[i][0])
          maxX = Math.max(maxX, data[i][0])
          minY = Math.min(minY, data[i][1])
          maxY = Math.max(maxY, data[i][1])
        }
        var kx = 900 / (maxX - minX)
        var ky = 900 / (maxY - minY)
        res[0] = kx
        res[1] = ky
        res[2] = minX
        res[3] = minY
        return res
      }
      //转换坐标
      var convertCoord = function (data, kx, ky, minX, minY) {
        var res = []
        if (data.length == null) {
          return res
        }
        if (is2DArray(data)) {
          for (var i = 0; i < data.length; i++) {
            var ss = []
            ss[0] = (data[i][0] - minX) * kx + 50
            ss[1] = (data[i][1] - minY) * ky + 50
            res.push(ss)
          }
        } else {
          var ss = []
          ss[0] = (data[0] - minX) * kx + 50
          ss[1] = (data[1] - minY) * ky + 50
          res.push(ss)
        }

        return res
      }
      //深拷贝对象
      var clone = function (obj) {
        return JSON.parse(JSON.stringify(obj))
      }

      //判断数组的维数
      function is2DArray(a) {
        // let str1 = '二维'
        // let str2 = '一维'
        for (let i of a) {
          if (Array.isArray(i)) return true
        }
        return false
      }

      //为每个单页图填充任务坐标
      var mergeAllLocations = function (mainBase, P1, P2, P3, norm) {
        var allPositions = []
        for (var i = 0; i < mainBase.length; i++) {
          var ap1 = []
          for (var j = 0; j < mainBase[i].length; j++) {
            ap1.push(mainBase[i][j])
          }
          for (var j = 0; j < P1[i].length; j++) {
            ap1.push(P1[i][j])
          }
          for (var j = 0; j < P2[i].length; j++) {
            ap1.push(P2[i][j])
          }
          for (var j = 0; j < P3[i].length; j++) {
            ap1.push(P3[i][j])
          }
          if (i == 0) {
            for (var j = 0; j < norm.length; j++) {
              ap1.push(norm[j])
            }
          }
          allPositions.push(ap1)
        }

        return allPositions
      }

      //计算缩放系数
      var computeK = function (positions) {
        var k = []
        for (var i = 0; i < positions.length; i++) {
          k[i] = convertRatio(positions[i])
        }
        return k
      }

      //标准化运动轨迹
      var traceCoord = function (positions) {
        var res = []
        res.push({
          coords: positions
        })
        return res
      }

      //单页图的坐标数组
      var allPositions = mergeAllLocations(
        this.maintainBase,
        this.maintainTaskP1,
        this.maintainTaskP2,
        this.maintainTaskP3,
        this.normalTasks
      )
      // console.log(allPositions);
      var kka = computeK(allPositions) //求各单页任务坐标的缩放系数
      // console.log(kka);

      //////////////////////////////////////////
      var sysColors = [
        '#ff9900', //黄色-维修基地
        '#980000', //红色-优先级1
        '#ffd966', //淡黄色-优先级2
        '#04B9FF', //蓝色-优先级3
        '#1DE9B6', //绿色-正常任务
        '#ffffff' //白色-转移路线
      ]

      // var year = ["全局", "站点1", "站点2", "站点3", "站点4"];
      this.$echarts.registerMap('vrpMap', geoJson)
      var that = this
      var optionXyMap01 = {
        //底部滚动栏
        timeline: {
          data: this.maintainBase,
          axisType: 'category',
          autoPlay: true,
          playInterval: 10000,
          left: '10%',
          right: '10%',
          bottom: '3%',
          width: '80%',
          label: {
            normal: {
              textStyle: {
                color: '#ddd'
              }
            },
            emphasis: {
              textStyle: {
                color: '#fff'
              }
            }
          },
          symbolSize: 10,
          lineStyle: {
            color: '#555'
          },
          checkpointStyle: {
            borderColor: '#87CEEB',
            borderWidth: 2
          },
          controlStyle: {
            showNextBtn: true,
            showPrevBtn: true,
            normal: {
              color: '#666',
              borderColor: '#666'
            },
            emphasis: {
              color: '#aaa',
              borderColor: '#aaa'
            }
          }
        },
        baseOption: {
          animation: true,
          animationDuration: 1000,
          animationEasing: 'cubicInOut',
          animationDurationUpdate: 1000,
          animationEasingUpdate: 'cubicInOut',
          //柱状图样式
          grid: [
            {
              left: '95%',
              top: '34.5%',
              bottom: '44.5%',
              width: '20%'
            },
            {
              gridindex: 1,
              left: '65%',
              right: '3%',
              top: '65%',
              bottom: '10%',
              containLabel: true
            }
          ],
          tooltip: {
            trigger: 'axis', // hover触发器
            axisPointer: {
              // 坐标轴指示器，坐标轴触发有效
              type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
              shadowStyle: {
                color: '#aaa' //hover颜色
              }
            }
          },

          legend: [
            {
              data: ['维修站点', '任务点', '转移线路'],
              top: '8%',
              left: '55%',
              textStyle: {
                color: '#fff'
              }
            }
          ],

          //地图样式
          geo: {
            show: true,
            layoutCenter: ['50%', '50%'],
            aspectScale: 1,
            layoutSize: '90%',
            // left: "35%",
            map: 'vrpMap',
            roam: true,
            zoom: 0.8,
            // center: [116.453, 39.92],
            // center: [500, 500],

            // center: ['50%', '50%'],

            label: {
              emphasis: {
                show: false
              }
            },
            itemStyle: {
              normal: {
                borderColor: 'rgba(147, 235, 248, 1)',
                borderWidth: 1,
                areaColor: {
                  type: 'radial',
                  x: 0.5,
                  y: 0.5,
                  r: 0.8,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(147, 235, 248, 0)' // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(147, 235, 248, .2)' // 100% 处的颜色
                    }
                  ],
                  globalCoord: false // 缺省为 false
                },
                shadowColor: 'rgba(128, 217, 248, 1)',
                // shadowColor: 'rgba(255, 255, 255, 1)',
                shadowOffsetX: -2,
                shadowOffsetY: 2,
                shadowBlur: 10
              },
              emphasis: {
                areaColor: '#389BB7',
                borderWidth: 0
              }
            }
          }
        },

        options: []
      }

      //为每个单页图绘制任务点
      for (var n = 0; n < this.maintainBase.length; n++) {
        optionXyMap01.options.push({
          //背景色
          //   backgroundColor: '#013954',
          //标题
          title: [
            {
              text: '维修路线图',
              x: 'center',
              top: '3%',
              textStyle: {
                color: '#fff',
                fontSize: 25
              }
            }
          ],

          series: [
            //正常的任务点
            {
              name: '任务点',
              type: 'effectScatter',
              coordinateSystem: 'geo',
              // data:[],
              data:
                n == 0
                  ? convertCoord(
                      that.normalTasks,
                      kka[0][0],
                      kka[0][1],
                      kka[0][2],
                      kka[0][3]
                    )
                  : [],
              showEffectOn: 'render',
              rippleEffect: {
                brushType: 'stroke'
              },
              hoverAnimation: true,
              label: {
                normal: {
                  formatter: '正常',
                  position: 'right',
                  show: true
                }
              },
              itemStyle: {
                normal: {
                  color: sysColors[4]
                }
              },
              zlevel: 1
            },
            //优先级为1的任务点
            {
              name: '任务点',
              type: 'effectScatter',
              coordinateSystem: 'geo',
              // data:[],
              data: convertCoord(
                that.maintainTaskP1[n],
                kka[n][0],
                kka[n][1],
                kka[n][2],
                kka[n][3]
              ),
              showEffectOn: 'render',
              rippleEffect: {
                brushType: 'stroke'
              },
              hoverAnimation: true,
              label: {
                normal: {
                  formatter: '紧急',
                  position: 'right',
                  show: true
                }
              },
              itemStyle: {
                normal: {
                  color: sysColors[1]
                }
              },
              zlevel: 1
            },
            //优先级为2的任务点
            {
              name: '任务点',
              type: 'effectScatter',
              coordinateSystem: 'geo',
              // data:[],
              data: convertCoord(
                that.maintainTaskP2[n],
                kka[n][0],
                kka[n][1],
                kka[n][2],
                kka[n][3]
              ),
              showEffectOn: 'render',
              rippleEffect: {
                brushType: 'stroke'
              },
              hoverAnimation: true,
              label: {
                normal: {
                  formatter: '待处理',
                  position: 'right',
                  show: true
                }
              },
              itemStyle: {
                normal: {
                  color: sysColors[2]
                }
              },
              zlevel: 1
            },
            //优先级为3的任务点
            {
              name: '任务点',
              type: 'effectScatter',
              coordinateSystem: 'geo',
              // data:[],
              data: convertCoord(
                that.maintainTaskP3[n],
                kka[n][0],
                kka[n][1],
                kka[n][2],
                kka[n][3]
              ),
              showEffectOn: 'render',
              rippleEffect: {
                brushType: 'stroke'
              },
              hoverAnimation: true,
              label: {
                normal: {
                  formatter: '预防',
                  position: 'right',
                  show: true
                }
              },
              itemStyle: {
                normal: {
                  color: sysColors[3]
                }
              },
              zlevel: 1
            },
            //基站点显示
            {
              name: '维修站点',
              type: 'effectScatter',
              coordinateSystem: 'geo',
              // data: convertData1(
              //   database
              //     .sort(function (a, b) {
              //       return b.value - a.value;
              //     })
              //     .slice(0, 20)
              // ),
              data: convertCoord(
                that.maintainBase[n],
                kka[n][0],
                kka[n][1],
                kka[n][2],
                kka[n][3]
              ),
              symbol: 'triangle',
              symbolSize: 20,
              showEffectOn: 'render',
              rippleEffect: {
                brushType: 'stroke'
              },
              hoverAnimation: true,
              label: {
                normal: {
                  formatter: '维保站',
                  position: 'right',
                  show: true
                }
              },
              itemStyle: {
                normal: {
                  color: sysColors[0],
                  shadowBlur: 30,
                  shadowColor: '#073763'
                },
                emphasis: {
                  color: '#fff',
                  shadowBlur: 30,
                  shadowColor: '#cfff'
                }
              },
              zlevel: 1
            }
          ]
        })
      }

      //为每个单页图添加路径
      for (var r = 0; r < this.maintainBase.length; r++) {
        for (var c = 0; c < this.maintainPaths[r].length; c++) {
          optionXyMap01.options[r].series.push({
            name: '转移线路',
            type: 'lines',
            zlevel: 2,
            effect: {
              show: true,
              period: 20, //箭头指向速度，值越小速度越快
              trailLength: 0.02, //特效尾迹长度[0,1]值越大，尾迹越长重
              symbol: 'arrow', //箭头图标
              symbolSize: 5 //图标大小
            },
            lineStyle: {
              normal: {
                color: sysColors[5],
                width: 0.1, //尾迹线条宽度
                opacity: 0.5, //尾迹线条透明度
                curveness: 0.3 //尾迹线条曲直度
              }
            },
            polyline: true,
            //绘制路线
            data: traceCoord(
              convertCoord(
                that.maintainPaths[r][c],
                kka[r][0],
                kka[r][1],
                kka[r][2],
                kka[r][3]
              )
            )
          })
        }
      }

      var domVRP = 'vrp-path'
      this.drawLine(domVRP, optionXyMap01)

      // myChart.setOption(optionXyMap01);
    }
  }
}
</script>

<style lang="scss" scoped>
#vrp-path {
  width: 100%;
  height: 100%;
  float: left;
  /* background-color: rgb(162, 160, 187); */
}
</style>
